export default {
    ad1_info: "создайте свой уникальный идентификатор, отсканировав свою ладонь, и покажите миру, что вы человек!",
    ad1_btn_text: "Подтвердите свой идентификатор человека",
    palm_tabbar_title: "Human ID",
    palm_tip: "Сканировать правую ладонь",
    palm_tip_left: "Двигай влево",
    palm_tip_right: "Двигаться вправо",
    palm_tip_down: "Двигаться вниз",
    palm_tip_up: "Вверх",
    palm_tip_closer: "Подойдите ближе",
    palm_tip_farther: "Двигайтесь дальше",
    palm_tip_keep: "не шуметь",
    palm_tip_slow: "Пожалуйста, помедленнее",
    palm_tip_fist: "Медленно сожми кулак",
    palm_btn_ok: "хорошо",
    palm_id_card_title: "REPUBLIC OF HUMANITY",
    palm_id_card_id: "HUMAN ID",
    palm_id_card_date: "Date of Issue：",
    palm_text: "Правая рука",
    palm_option_item1: "Что мы делаем",
    palm_option_item2: "Спецификация API идентификатора человека",
    palm_timeout_tip1: "Sorry, the verification period has expired.",
    palm_timeout_tip2: "Please try again.",
    palm_broswer_error: "This version of the browser is not currently supported",
    palm_guide_tip1: "Open a palm and spread fingers naturally",
    palm_guide_tip2: "Have your palm parallel to the screen",
    palm_guide_tip3: "When the screen is flickering, please keep your palm steady",
    palm_guide_tip4: "Align palm with the scanning frame",
    palm_guide_tip5: "Unidentified palm",
    palm_start: "Start",
    palm_nohuman_tip1: "This is not the palm registered on this phone.",
    palm_nohuman_tip2: "Please try in better light conditions, or use another phone.",
    palm_liveness_error: "Please keep your palm away from facing strong light sources."
}
