export default {
    ad1_info: "Erstellen Sie Ihre einzigartige ID, indem Sie Ihre Handfläche scannen, und zeigen Sie der Welt, dass Sie ein Mensch sind!",
    ad1_btn_text: "Überprüfen Sie Ihre menschliche ID",
    palm_tabbar_title: "Human ID",
    palm_tip: "Scannen Sie die rechte Handfläche",
    palm_tip_left: "Geh nach links",
    palm_tip_right: "Nach rechts bewegen",
    palm_tip_down: "Sich abwärts bewegen",
    palm_tip_up: "Bewegen Sie sich nach oben",
    palm_tip_closer: "Komm näher",
    palm_tip_farther: "Bewegen Sie sich weiter",
    palm_tip_keep: "Ruhig zu halten",
    palm_tip_slow: "Bitte fahren Sie langsamer",
    palm_tip_fist: "Machen Sie langsam eine Faust",
    palm_btn_ok: "OK",
    palm_id_card_title: "REPUBLIC OF HUMANITY",
    palm_id_card_id: "HUMAN ID",
    palm_id_card_date: "Date of Issue：",
    palm_text: "Rechte Hand",
    palm_option_item1: "Was wir tun",
    palm_option_item2: "Spezifikation der Human-ID-APIs",
    palm_timeout_tip1: "Sorry, the verification period has expired.",
    palm_timeout_tip2: "Please try again.",
    palm_broswer_error: "This version of the browser is not currently supported",
    palm_guide_tip1: "Open a palm and spread fingers naturally",
    palm_guide_tip2: "Have your palm parallel to the screen",
    palm_guide_tip3: "When the screen is flickering, please keep your palm steady",
    palm_guide_tip4: "Align palm with the scanning frame",
    palm_guide_tip5: "Unidentified palm",
    palm_start: "Start",
    palm_nohuman_tip1: "This is not the palm registered on this phone.",
    palm_nohuman_tip2: "Please try in better light conditions, or use another phone.",
    palm_liveness_error: "Please keep your palm away from facing strong light sources."
}
