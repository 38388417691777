<template>
  <div class="van-toast" id="dialog_toast">
    <div id="toast_icon" class="suc"></div>
    <div class="van-toast__text" id="toast_msg">提示内容</div>
  </div>
  <div class="web_error" id="error_id" v-show="nPageType===1">
    <img :src="pageError" alt="">
    <h2 class="spec_error">{{ sTipsMsg }}</h2>
  </div>
  <div class="web_success" v-show="nPageType===2">
    <img :src="pageSuccess" alt="">
    <h2 class="spec_suc">{{ sTipsMsg }}</h2>
  </div>
  <PalmVerify ref="refPalmEle"  v-if="nPageType===3" @cbShowCode = 'fnShowCode'/>
  <VerifyCode v-if="isShowVerify" @submitVerify = 'fnHideVerify'/>
</template>

<script>
import PalmVerify from "@/components/PalmVerify.vue";
import VerifyCode from "@/components/VerifyCode.vue";
import pageError from "@/assets/image/icon_error.png";
import pageSuccess from "@/assets/image/icon_suc.png";
import FingerprintJS from "@fingerprintjs/fingerprintjs";
import {getUrlParam} from "@/config/utils";
import {apiAuth} from "@/config/api";
import i18n from "@/language";
const lang = i18n.global.t
export default {
  name: 'App',
  components: {
    PalmVerify,
    VerifyCode,
  },
  data:function() {
    return {
      pageError,
      pageSuccess,
      sTipsMsg:'',
      isShowVerify:false,
      nPageType:4, // 1,错误，2 成功，3显示，4 其他
      nPageIndex: 2
  };
},
  async mounted() {
    sessionStorage.removeItem('specialUrl')
    let nFontSize = 64
    updateFontSize();

    function updateFontSize() {
      let screenWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
      screenWidth = screenWidth > 640 ? 640 : screenWidth
      nFontSize = Math.min(screenWidth * 0.1, 640); // 10% of the viewport width, but not exceeding 640px
      document.documentElement.style.fontSize = `${nFontSize}px`;
    }

    // const searchParams = new URLSearchParams(window.location.search);
    // const params = Object.fromEntries(searchParams.entries());
    // if (!params.palm) {
    //   this.sTipsMsg = '当前页面链接已失效'
    //   this.nPageType = 1
    //   return
    // }  // 不需要判断链接是否无效

    await this.fnGetFingerprintId()
    await this.fnCheckUrl()
  },
  methods: {
    async fnCheckUrl() {
      let str = location.search.slice(1);
      if(getUrlParam('website') && getUrlParam('website')==1){
        const newFpValue = await this.fnGetFingerprintId()
        str = str.replace(/(fp=)[^&]+/, `$1${newFpValue}`);
      }
      const {code,msg} = await apiAuth({query:str})
      if(code === 0){
        this.nPageType = 3
        if(getUrlParam('website')!=1 && !getUrlParam('palm')){
          // 需要验证码
          this.isShowVerify = true
        }else if(['1', '2'].includes(getUrlParam('palm'))){
          await this.fnCheckPalmEle()
          this.$refs.refPalmEle.fnOpenVideo()
        }else if(getUrlParam('website') && getUrlParam('website')==1){
          // 免验证码直接链接
          sessionStorage.setItem('specialUrl', str)
        }
      }else{
        let sTempMsg = ''
        switch (code) {
          case 100057:
            sTempMsg=lang('check_used_re_enter')
            break
          case 100058:
            sTempMsg=lang('check_used_check_again')
            break
          case  100085:
            sTempMsg = lang('code_check_error_100085')
            break
          default:
            sTempMsg = msg
            break
        }
        this.sTipsMsg = sTempMsg
        this.nPageType = 1
        this.isShowVerify = false
      }
    },
    fnCheckPalmEle() {
      return new Promise((resolve) => {
        const interval = setInterval(() => {
          if (this.$refs.refPalmEle) {
            clearInterval(interval);
            resolve(true);
          }
        }, 100);
      });
    },
    fnHideVerify(res){
      this.isShowVerify = false

      if(!res.isAccess){
        this.nPageType = 1
        this.sTipsMsg = res.msg
      }
    },
    fnShowCode(res){
      if(res.isPalmJump && res.result?.succ){
        if(res.result.jump_url){
          setTimeout(()=>{
            window.location.href=res.result.jump_url
          },2 * 1000)
        }else{
          this.nPageType = 2
          let tempMsg = res.result?.msg||''
          if(getUrlParam('palm')){
            if(getUrlParam('palm') == 1){
              tempMsg = lang('code_save_succ')
            }else if(getUrlParam('palm') ==2){
              tempMsg = lang('code_check_succ')
            }
          }
          this.sTipsMsg = tempMsg
        }
        return
      }
      this.isShowVerify = res.isShowVerify
      if(res.nPageType){
        this.nPageType = res.nPageType
      }
    },
    async fnGetFingerprintId() {
      // 尝试从 localStorage 获取 fpId
      const storedFingerprintId = localStorage.getItem('fpId');

      if (storedFingerprintId) {
        // 如果 localStorage 中有 fpId，直接返回
        return storedFingerprintId;
      } else {
        // 如果没有 fpId，则生成新的指纹 ID
        const fpPromise = await FingerprintJS.load();
        const fp = await fpPromise;
        const result = await fp.get();

        // 添加用户代理信息到指纹组件
        result.components.user_agent = {
          "value": navigator.userAgent,
          "duration": 1
        };

        // 生成指纹 ID
        const fingerprintId = FingerprintJS.hashComponents(result.components);

        // 将指纹 ID 存储到 localStorage
        localStorage.setItem('fpId', fingerprintId);

        // 返回新生成的指纹 ID
        return fingerprintId;
      }
    }
  }
}
</script>

<style>
body,html,h1,p {
  padding: 0;
  margin: 0;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  max-width: 640px;
  margin: 0 auto;
  transition: all 0.5s ease-in-out;
  font-size: 34px;
}
.hidden{
  display: none;
}

.web_error,
.web_success {
  margin: 2rem auto;
  width: 90%;
  text-align: center;
}

.web_error h2,
.web_success h2 {
  font-size: 24px;
  font-weight: normal;
  margin: 0 auto;
  text-align: center;
}

.web_error p {
  font-size: 14px;
  color: #838383;
  white-space: nowrap;
}

.web_error img {
  max-width: 45%;
  max-height: 45%;
}

.web_success img {
  max-width: 70%;
  max-height: 70%;
}

.spec_error {
  color: #ff2d2d;
}

.spec_suc {
  color: #6fc140;
}
.ml-2{
  margin-left: 20px;
}


.van-toast {
  display: flex;
  /* flex-direction: column; */
  align-items: center;
  justify-content: center;
  box-sizing: content-box;
  transition: 0.2s;
  font-size: 26px;
  line-height: 22px;
  white-space: pre-wrap;
  word-break: break-all;
  text-align: center;
  border-radius: 8px;
  top: 38%;
  left: 0;
  right: 0;
  max-width: calc(100vw - 32px);
  margin: 0 auto;
  transform: translatey(-50%);
  position: fixed;
  width: fit-content;
  min-width: 96px;
  min-height: 0;
  padding: 28px 40px;
  z-index: 999999;
  visibility: hidden;
  color: #6fc140;
  background-color: #f0f9eb;
  border-color: #e1f3d8;
}

.van-toast-suc {
  color: #6fc140;
  background-color: #f0f9eb;
  border-color: #e1f3d8;
}

.tips_msg.tips_suc {
  color: #6fc140;
}

.tips_msg.tips_err {
  color: #f56c6c;
}

.van-toast-err {
  color: #f56c6c;
  background-color: #fef0f0;
  border-color: #fde2e2;
}

/* 对号样式 */
#toast_icon::before {
  font-size: 30px;
  padding-right: 10px;
  font-weight: bold;
}

#toast_icon.suc::before {
  content: '\2713';
  color: lime;
}

/* 叹号样式 */
#toast_icon.err::before {
  content: '\0021';
  /* Unicode字符: ! */
  color: #ff2d2d;
}

.tips_msg {
  width: 100%;
  color: #000000;
  font-weight: bold;
  margin-top: 0;
  text-align: center;
  font-size: 40px;
}
.van-toast__text{
  line-height: 30px;
}
</style>
