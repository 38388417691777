export default {
    ad1_info: "손바닥을 스캔하여 고유 ID를 만들고 세상에 당신이 인간임을 보여주세요!",
    ad1_btn_text: "귀하의 개인 ID를 확인하십시오",
    start_info: "입력을 시작하려면 클릭하세요",
    start_info2: "검증을 시작하려면 클릭하세요",
    start_write: "입력 시작",
    start_check: "검증 시작",
    start_refresh: "재입력",
    palm_tabbar_title: "Human ID",
    palm_tip: "오른쪽 손바닥 스캔",
    palm_tip_left: "왼쪽으로 이동",
    palm_tip_right: "오른쪽으로 이동해라",
    palm_tip_down: "아래로 이동",
    palm_tip_up: "이동",
    palm_tip_closer: "더 가까이 다가가세요",
    palm_tip_farther: "더 멀리 이동",
    palm_tip_keep: "가만히 있어",
    palm_tip_slow: "속도를 줄여주세요",
    palm_tip_fist: "천천히 주먹을 쥐어보세요",
    palm_btn_ok: "좋아요",
    palm_id_card_title: "REPUBLIC OF HUMANITY",
    palm_id_card_id: "HUMAN ID",
    palm_id_card_date: "Date of Issue：",
    palm_text: "오른손",
    palm_option_item1: "우리가 하는 일",
    palm_option_item2: "Human ID API 사양",
    palm_timeout_tip1: "Sorry, the verification period has expired.",
    palm_timeout_tip2: "Please try again.",
    palm_broswer_error: "This version of the browser is not currently supported",
    palm_guide_tip1: "Open a palm and spread fingers naturally",
    palm_guide_tip2: "Have your palm parallel to the screen",
    palm_guide_tip3: "When the screen is flickering, please keep your palm steady",
    palm_guide_tip4: "Align palm with the scanning frame",
    palm_start: "Start",
    palm_nohuman_tip1: "This is not the palm registered on this phone.",
    palm_nohuman_tip2: "Please try in better light conditions, or use another phone.",
    palm_liveness_error: "Please keep your palm away from facing strong light sources.",
    tips_label1: "손바닥을 사용하세요",
    tips_label2: "손바닥을 인식 영역 내에 놓으세요",
    tips_label3: "인식 거리는 약 0.1미터~0.2미터입니다",
    tips_label4: "검증을 시작하기 전에 밝은 배경을 선택하세요"
}
