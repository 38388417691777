export default {
    ad1_info: "手のひらをスキャンして独自の ID を作成し、あなたが人間であることを世界に示しましょう！",
    "code_succ": "成功",
    "code_wrong_args": "引数エラー",
    "code_hand_not_found": "区域内に手の平を置いてください",
    "code_sharp": "揺らさないでください",
    "code_low_brightness": "明るさが足りないです、明るい場所に移動して続行してください",
    "code_high_brightness": "強光下では使用できません、手の平を検出できません",
    "code_hand_not_open": "手の平をできるだけ開いてください",
    "code_saving": "記録中、手の平をゆっくりと回転してください",
    "code_save_succ": "記録成功",
    "code_save_error": "記録失敗",
    "code_checking": "確認中 {}",
    "code_check_succ": "確認成功",
    "code_check_error": "確認失敗",
    "code_check_process": "図解に従ってジェスチャーを示してください",
    "code_check_process_next": "一致成功、次のジェスチャーを図解に従って示してください",
    "code_check_not_found": "掌紋データが存在しません、再録入してください",
    start_info: "録入を開始するにはクリックしてください",
    start_info2: "検証を開始するにはクリックしてください",
    start_write: "録入を開始",
    start_check: "検証を開始",
    start_refresh: "再録入",
    ad1_btn_text: "人間 ID を確認してください",
    palm_tabbar_titlme: "Human ID",
    palm_tip: "右手のひらをスキャン",
    palm_tip_left: "左に移動",
    palm_tip_right: "右に動く",
    palm_tip_down: "下に移動",
    palm_tip_up: "上に移動",
    palm_tip_closer: "近寄って",
    palm_tip_farther: "さらに遠くへ移動",
    palm_tip_keep: "じっとしています",
    palm_tip_slow: "減速してください",
    palm_tip_fist: "ゆっくりと拳を握ります",
    palm_btn_ok: "わかりました",
    palm_id_card_title: "REPUBLIC OF HUMANITY",
    palm_id_card_id: "HUMAN ID",
    palm_id_card_date: "Date of Issue：",
    palm_text: "右手",
    palm_option_item1: "私達がすること",
    palm_option_item2: "Human ID API 仕様",
    palm_timeout_tip1: "Sorry, the verification period has expired.",
    palm_timeout_tip2: "Please try again.",
    palm_broswer_error: "This version of the browser is not currently supported",
    palm_guide_tip1: "Open a palm and spread fingers naturally",
    palm_guide_tip2: "Have your palm parallel to the screen",
    palm_guide_tip3: "When the screen is flickering, please keep your palm steady",
    palm_guide_tip4: "Align palm with the scanning frame",
    palm_start: "Start",
    palm_nohuman_tip1: "This is not the palm registered on this phone.",
    palm_nohuman_tip2: "Please try in better light conditions, or use another phone.",
    palm_liveness_error: "Please keep your palm away from facing strong light sources.",
    tips_label1: "手の平を利用してください",
    tips_label2: "認識領域内に手の平を置いてください",
    tips_label3: "認識距離は約0.1メートル～0.2メートルです",
    tips_label4: "認証を開始する前に明るい背景を選択してください"
}
