export default {
    ad1_info: "扫描手掌，创建您独一无二的ID，向世界展示您是人类！",
    write_code:'请输入验证码',
    write_code2:'请输入推广码',
    video_open_err:'相机启动失败',
    code_button_txt1:'切换推广码',
    code_button_txt2:'切换验证码',
    check_used_re_enter:'验签已使用，请重新录入！',
    check_used_check_again:'验签已使用，请重新检验！',
    "code_succ": "成功",
    "code_wrong_args": "参数错误",
    "code_hand_not_found": "请将手掌放到区域内",
    "code_sharp": "请不要抖动",
    "code_low_brightness": "光线太暗，请移至明亮处继续",
    "code_high_brightness": "请勿在强光下使用，检测不到手掌",
    "code_hand_not_open": "请将手掌尽量打开",
    "code_saving": "录入中，请缓慢转动你的手掌",
    "code_save_succ": "录入成功",
    "code_save_error": "录入失败",
    "code_checking": "校验中 {}",
    "code_check_succ": "校验成功",
    "code_check_error": "校验失败",
    code_check_error_100049: "当日试用次数已达上限",
    code_check_error_100085: "api调用已上限",
    "code_check_process": "请按图例摆出手势",
    "code_check_process_next": "匹配成功，请按图例摆出下个手势",
    "code_check_not_found": "掌纹数据不存在，请重新录入",
    start_info:'请点击开始进行录入',
    start_info2:'请点击开始进行验证',
    cancel:'取消',
    start_write:'开始录入',
    start_check:'开始验证',
    start_refresh:'重新录入',
    ad1_btn_text: "验证您的人类身份",
    palm_tabbar_title: "Human ID",
    palm_tip: "扫描右掌",
    palm_tip_left: "向左移动",
    palm_tip_right: "向右移动",
    palm_tip_up: "向上移动",
    palm_tip_down: "向下移动",
    palm_tip_closer: "移近一点",
    palm_tip_farther: "移远一点",
    palm_tip_keep: "保持静止",
    palm_tip_slow: "请慢一点",
    palm_tip_fist: "慢慢握拳",
    palm_btn_ok: "完成",
    palm_id_card_title: "REPUBLIC OF HUMANITY",
    palm_id_card_id: "HUMAN ID",
    palm_id_card_date: "Date of Issue：",
    palm_text: "请用右手",
    palm_option_item1: "我们在做什么",
    palm_option_item2: "API文档",
    palm_timeout_tip1: "抱歉，验证期限已过。",
    palm_timeout_tip2: "请再试一次。",
    palm_broswer_error: "目前不支持该版本的浏览器",
    palm_guide_tip1: "张开右手手掌\n自然展开手指",
    palm_guide_tip2: "手掌与屏幕平行",
    palm_guide_tip3: "屏幕闪烁时\n请保持手掌固定",
    palm_guide_tip4: "将手掌与扫描框对齐",
    palm_guide_tip5: "未识别的手掌",
    palm_start: "开始",
    palm_nohuman_tip1: "这不是本手机上注册的手掌。",
    palm_nohuman_tip2: "请在更好的光线条件下尝试，或使用其他手机。",
    palm_liveness_error: "请避免强光直射手掌",
    tips_label1:'请用手掌正面进行',
    tips_label2:'请将手掌置于识别区域内',
    tips_label3:'识别距离约为0.1米-0.2米',
    tips_label4:'开始验证前请尽量选择光亮的背景',
};
