import {get, post} from "@/config/http";

const  Api = {
    auth: '/auth',
    code: '/code',
    codeVerify: '/code_verify',
    trialVerify: '/trial_verify',
    save: '/save',
    check: '/check',
}
export function apiGetCode() {
  return get(Api.code)
}
export function apiCodeVerify(data) {
  return post(Api.codeVerify,data)
}
export function apiTrialVerify(data) {
  return post(Api.trialVerify,data)
}
export function apiSave(data) {
  return post(Api.save,data)
}
export function apiCheck(data) {
  return post(Api.check,data)
}
export function apiAuth(data) {
  return post(Api.auth,data)
}
