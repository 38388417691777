export default {
    ad1_info: "create your unique ID by scanning your palm and show the world you are human !",
    write_code:'Please enter the verification code',
    write_code2:'Please enter the promotion code',
    check_used_re_enter:'Check visa has been used, please re-enter!',
    check_used_check_again:'Check has been used, please check again!',
    "code_succ": "Success",
    video_open_err:'Failed to start the camera',
    "code_wrong_args": "Wrong arguments",
    "code_hand_not_found": "Please place your palm within the area",
    "code_sharp": "Please do not shake",
    "code_low_brightness": "The light is too dim, please move to a brighter place to continue",
    "code_high_brightness": "Please do not use under strong light, the palm cannot be detected",
    "code_hand_not_open": "Please open your palm as much as possible",
    "code_saving": "Recording in progress, please slowly rotate your palm",
    "code_save_succ": "Recording successful",
    "code_save_error": "Recording failed",
    "code_checking": "Checking in progress {}",
    "code_check_succ": "Check successful",
    "code_check_error": "Check failed",
    code_check_error_100049: "The number of trials on the day reached the upper limit",
    code_check_error_100085: "api calls are capped",
    "code_check_process": "Please pose the gesture according to the diagram",
    "code_check_process_next": "Match successful, please pose the next gesture according to the diagram",
    "code_check_not_found": "Palm print data does not exist, please record again",
    "start_info": "Please click to start recording",
    "start_info2": "Please click to start verification",
    cancel:'cancel',
    "start_write": "Start recording",
    "start_check": "Start verification",
    "start_refresh": "Re-record",
    ad1_btn_text: "Verify Your Human ID",
    palm_tabbar_title: "Human ID",
    palm_tip: "Scan right palm",
    palm_tip_left: "Move left",
    palm_tip_right: "Move right",
    palm_tip_down: "Move down",
    palm_tip_up: "Move up",
    palm_tip_closer: "Move closer",
    palm_tip_farther: "Move farther",
    palm_tip_keep: "Keep still",
    palm_tip_slow: "Please slow down",
    palm_tip_fist: "Make a fist slowly",
    palm_btn_ok: "OK",
    palm_id_card_title: "REPUBLIC OF HUMANITY",
    palm_id_card_id: "HUMAN ID",
    palm_id_card_date: "Date of Issue：",
    palm_text: "Right hand",
    palm_option_item1: "What we do",
    palm_option_item2: "Human ID APIs Specification",
    palm_timeout_tip1: "Sorry, the verification period has expired.",
    palm_timeout_tip2: "Please try again.",
    palm_broswer_error: "This version of the browser is not currently supported",
    palm_guide_tip1: "Open a palm and spread fingers naturally",
    palm_guide_tip2: "Have your palm parallel to the screen",
    palm_guide_tip3: "When the screen is flickering, please keep your palm steady",
    palm_guide_tip4: "Align palm with the scanning frame",
    palm_guide_tip5: "Unidentified palm",
    palm_start: "Start",
    palm_nohuman_tip1: "This is not the palm registered on this phone.",
    palm_nohuman_tip2: "Please try in better light conditions, or use another phone.",
    palm_liveness_error: "Please keep your palm away from facing strong light sources.",
    tips_label1: "Please use the palm of your hand",
    tips_label2: "Please place your palm within the recognition area",
    tips_label3: "Recognition distance is approximately 0.1 meters to 0.2 meters",
    tips_label4: "Please try to choose a bright background before starting verification"
}
