<template>
  <section class="verify_body">
    <div class="verify_main">
      <div class="verify_code" v-show="!isSpread">
        <div class="verify-code_input">
          <input type="text" :placeholder="$t('write_code')" v-model="sCode"/>
        </div>
        <div class="verify-code_img" @click="fnGetVerifyCode">
          <img :src="sCaptchaUrl" alt="">
        </div>
      </div>
      <div class="verify_code" v-show="isSpread">
        <div class="verify-code_input">
          <input type="text" :placeholder="$t('write_code2')" v-model="sTrialCode"/>
        </div>
      </div>
      <div class="verify_specific" @click="isSpread=!isSpread">
        {{isSpread?$t('code_button_txt2'):$t('code_button_txt1')}}
      </div>
      <div class="verify_button" @click="fnSubmitVerify">
        <div v-show="!isSubmit" class="loader"></div>
        <span>{{ $t('start_check') }}</span>
      </div>
    </div>
    <div class="verify_mask" v-if="isVerifyError">
      <div class="web_error" id="error_id" >
        <img :src="pageError" alt="">
        <h2 class="spec_error">{{ sTipsMsg }}</h2>
      </div>
    </div>
  </section>

</template>
<script setup>
import {onBeforeMount, ref, defineEmits} from "vue";
import {dialogShow} from "@/config/utils";
import pageError from "@/assets/image/icon_error.png";
import {apiCodeVerify, apiGetCode, apiTrialVerify} from "@/config/api";
import i18n from "@/language";
const emits = defineEmits(['submitVerify'])
const sCode = ref('')
const sTrialCode = ref('')
const sCaptchaUrl = ref('')
const sCaptchaId = ref('')
const sTipsMsg = ref('')
const isSubmit = ref(true)
const isVerifyError = ref(false)
const isSpread = ref(false)

const lang = i18n.global.t
onBeforeMount(() => {
  document.body.style.overflow = 'hidden';
  fnGetVerifyCode()
})

async function fnGetVerifyCode() {
  const {data,msg,code} = await apiGetCode()
  if(data && code === 0){
    sCaptchaUrl.value = data?.captcha_image ||''
    sCaptchaId.value = data?.captcha_id ||''
  }else{
   /* if(code === 100008){
      sTipsMsg.value = msg
      isVerifyError.value = true
    } else {
      dialogShow(false,msg)
    }*/
    dialogShow(false,msg)
  }
}

async function fnSubmitVerify() {
  if (!isSubmit.value) {
    return
  }
  if (!isSpread.value && sCode.value === '') {
    dialogShow(false, lang('write_code'))
    return
  }
  if (isSpread.value && sTrialCode.value === '') {
    dialogShow(false, lang('write_code2'))
    return
  }
  const fpId = localStorage.getItem('fpId')
  isSubmit.value = false
  try {
    let tempApi = apiCodeVerify
    let params = {code:sCode.value,captcha_id:sCaptchaId.value,fp:fpId}
    if(isSpread.value){
      params = {
        code:sTrialCode.value,
        fp:fpId,
      }
      tempApi = apiTrialVerify
    }
    const {data,msg,code} = await tempApi(params)
    if(data && code === 0) {
      sessionStorage.setItem('specialUrl', data?.url)
      document.body.style.overflowX = 'auto';
      emits('submitVerify', {isAccess:true})
    }else{
      dialogShow(false, msg)
    }
  }finally {
    isSubmit.value = true
  }
}
</script>

<style>
.verify_body {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, .5);
  z-index: 99999;
  overflow: hidden;
}

.verify_main {
  width: 470px;
  min-height: 150px;
  background: #fff;
  border-radius: 24px;
  color: #303133;
  padding: 50px 45px;
  font-size: 16px;
  position: relative;
  top: 46%;
  left: 50%;
  transform: translate(-50%, -50%); /* 偏移元素自身宽度和高度的50%来实现居中 */
}

.verify_code {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.verify-code_img {
  width: 250px;
  height: 70px;
  margin-left: 10px;
}

.verify-code_img img {
  width: 100%;
  max-height: 100%;
}

.verify-code_input {
  width: calc(100% - 56px);
  height: 70px;
  padding: 0 26px;
  border-radius: 14px;
  font-size: 26px;
  position: relative;
//color: #29c979; color: #1c84fd;
  border: 2px solid #1c84fd;
  background: #fff;
}
.verify_specific{
 font-size: 26px;
  margin-top: 20px;
  color: #2d94fc;
  margin-right: 10px;
  text-align: right;
}
.verify-code_input input {
  border: none !important;
  outline: none;
  height: 100%;
  background: transparent;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  font-size: 26px;
  width: 100%;
  position: relative;
  top: -3px;
}

.verify_button {
  width: 100%;
  height: 68px;
  background: linear-gradient(173deg, #45bbf2, #1c84fd);
  border-radius: 10px;
  color: #fff;
  cursor: pointer;
  margin-top: 30px;
  text-align: center;
  line-height: 68px;
  font-size: 26px;
}


.loader {
  border: 4px solid #f3f3f3; /* Light grey */
  border-top: 4px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 2s linear infinite;
  display: inline-block;
  margin-right: 10px;
  position: relative;
  top: 4px;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.verify_mask .web_error{
  background: #fff;
  padding: 50px 0;
  border-radius: 20px;
  position: absolute;
  top: 38%;
  left: 50%;
  width: 92%;
  min-height: 270px;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 700px;
}
.verify_mask .web_error img{
  width: 25%;
}
</style>
