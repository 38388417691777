import gestureS1 from '@/assets/image/gesture_s_1.png';
import gestureS2 from '@/assets/image/gesture_s_2.png';
import gestureS3 from '@/assets/image/gesture_s_3.png';
import gestureS4 from '@/assets/image/gesture_s_4.png';
import gestureS5 from '@/assets/image/gesture_s_5.png';

export const  varGestures = [gestureS1,gestureS2, gestureS3, gestureS4, gestureS5]

// 录入提示内容
export const  varPrompts = ['tips_label1',  'tips_label2',  'tips_label3',  'tips_label4']
