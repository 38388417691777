export default {
    ad1_info: "créez votre identifiant unique en scannant votre paume et montrez au monde que vous êtes humain !",
    ad1_btn_text: "Vérifiez votre identifiant humain",
    palm_tabbar_title: "Human ID",
    palm_tip: "Scanner la paume droite",
    palm_tip_left: "Se déplacer à gauche",
    palm_tip_right: "Déplacer vers la droite",
    palm_tip_down: "Descendre",
    palm_tip_up: "Déplacer vers le haut",
    palm_tip_closer: "Approchez-vous",
    palm_tip_farther: "Aller plus loin",
    palm_tip_keep: "Ne bouge pas",
    palm_tip_slow: "Ralentissez, s'il vous plaît",
    palm_tip_fist: "Serrez le poing lentement",
    palm_btn_ok: "D'ACCORD",
    palm_id_card_title: "REPUBLIC OF HUMANITY",
    palm_id_card_id: "HUMAN ID",
    palm_id_card_date: "Date of Issue：",
    palm_text: "Main droite",
    palm_option_item1: "Ce que nous faisons",
    palm_option_item2: "Spécification des API d'identification humaine",
    palm_timeout_tip1: "Sorry, the verification period has expired.",
    palm_timeout_tip2: "Please try again.",
    palm_broswer_error: "This version of the browser is not currently supported",
    palm_guide_tip1: "Open a palm and spread fingers naturally",
    palm_guide_tip2: "Have your palm parallel to the screen",
    palm_guide_tip3: "When the screen is flickering, please keep your palm steady",
    palm_guide_tip4: "Align palm with the scanning frame",
    palm_start: "Start",
    palm_nohuman_tip1: "This is not the palm registered on this phone.",
    palm_nohuman_tip2: "Please try in better light conditions, or use another phone.",
    palm_liveness_error: "Please keep your palm away from facing strong light sources."
}
