export function dialogShow(isOk, msg) {
    const dialogEle = document.getElementById('dialog_toast');
    const toastMsgEle = document.getElementById('toast_msg');
    const toastIconEle = document.getElementById('toast_icon');
    if(dialogEle){
        dialogEle.style.visibility = 'visible';
        toastIconEle.className = isOk ? 'suc' : 'err'
        toastMsgEle.innerHTML = msg || ''
        dialogEle.classList.toggle('van-toast-err', !isOk);
        setTimeout(() => {
            dialogEle.style.visibility = 'hidden';
        }, 3 * 1000)
    }
}

export function getUrlParam(paramName) {
    const url = new URL(window.location.href);
    const params = new URLSearchParams(url.search);
    const encodedValue = params.get(paramName);
    return encodedValue ? decodeURIComponent(encodedValue) : null;
}
