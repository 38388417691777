import axios from 'axios';

// 创建axios实例
const instance = axios.create({
    baseURL:fnGetBaseUrl(), // 设置基础URL
    timeout: 60 * 1000, // 设置请求超时时间
    headers: {
        'Content-Type': 'application/json', // 设置默认请求头
        // 可以添加其他默认请求头，如认证token等
        // 'Authorization': 'Bearer your-token-here'
    },
});

// 请求拦截器
instance.interceptors.request.use(
    (config) => {
        // 在发送请求之前做些什么，例如添加token到headers
        if (localStorage.getItem('token')) {
          config.headers.Authorization = `Bearer ${localStorage.getItem('token')}`;
        }
        return config;
    },
    (error) => {
        // 对请求错误做些什么
        return Promise.reject(error);
    }
);

// 响应拦截器
instance.interceptors.response.use(
    (response) => {
        // 对响应数据做点什么，例如返回数据而不是整个响应对象
        return response.data;
    },
    (error) => {
        // 对响应错误做点什么
        return Promise.reject(error);
    }
);

// 封装get请求
export async function get(url, params = {}) {
    try {
        return await instance.get(url, { params });
    } catch (error) {
        // 处理错误
        console.error('Error fetching data:', error);
        throw error;
    }
}
export async function post(url, data = {}) {
    try {
        return await instance.post(url, data);
    } catch (error) {
        // 处理错误
        console.error('Error posting data:', error);
        throw error;
    }
}

function fnGetBaseUrl() {
    let VITE_GLOB_API_URL = 'api2'
    if(process.env.NODE_ENV !== 'development'){
        let baseUrl = location.hostname;
        const strUrl = baseUrl.match(/.*\.(\w+)\.(\w+)$/);
        if (strUrl != null) {
            const joins = [baseUrl.split('.')[0] + '-api', strUrl[1], strUrl[2]];
            baseUrl = joins.join('.');
        }
        VITE_GLOB_API_URL = window.location.protocol + '//' + baseUrl;
    }
   return VITE_GLOB_API_URL;
}
