import { createApp } from 'vue'
import App from './App.vue'
import i18n from './language'; // 假设 i18n 实例导出为默认导出
const app = createApp(App);
app.config.globalProperties.$remBase = 64; // 设置基准的rem值
const resizeHandler = () => {
    const width = document.documentElement.clientWidth;

    const remBase = Math.min(width / 10, 64); // 设置最大rem基准值，这里假设最大宽度为 320px
    app.config.globalProperties.$remBase = remBase;
    document.documentElement.style.fontSize = remBase + 'px';
};
resizeHandler(); // 初始调用一次
window.addEventListener('resize', resizeHandler);

app.use(i18n);
app.mount('#app')
