<template>
  <main class="content_body" :class="sGestureClass">
    <section class="palm_body">
      <div style="height: 0.2rem" v-if="isChromeOpen"></div>
      <div class="palm_head" :class="{ 'tips_suc': nResultCode === 0, 'tips_err': nResultCode === 1,'chrome_sp':isChromeSp  }">
        <p>{{  $t(sTipsMsgEnd) }}</p>
<!--        <p>{{ sEspecialMsg }}</p>-->
      </div>
      <div class="palm_main">
        <div class="palm-main_content" ref="refMainContent">
          <div class="spinnerFourBox" v-show="isOpen && isStartLoading && !isVideoRun"></div>
          <div class="rotate_img" v-show="isVideoRun"></div>
          <div class="scan_main" id="cid_scan_main">
            <div class="mask_before" id="cid_mask_before"></div>
            <div class="scan-main_box" id="cid_scan_box"></div>
          </div>
          <RecordPalm  v-show="isVideoRun" :refCenterDot="refCenterDotEle" :oBoxWh = 'oBoxWh' :isOpen = "isOpen" :s-uid="sUUID" :is-Check="isCheck"
                      @cbOperateData="fnCallData"
                      @cbCentreXy="fnCenterXy"
                      @cbCentreZ="fnCentreZ"
                      @cbChangeBasicMsg="fnChangeBasicMsg"
                      @cbChangeEspecialMsg="fnChangeEspecialMsg"/>
        </div>
        <div class="guide_body" v-show="isVideoRun">
          <div class="guide-main_box">
            <div class="guide-main_fix"><div ref="refCenterDotEle" class="guide-main_fixC"></div><div class="guide-main_fixX"></div><div class="guide-main-fixY"></div></div>
            <div class="guide-main_dot" :style="oCenterPositionStyle"></div>
          </div>
        </div>
      </div>


      <div class="palm_footer">
<!--        <div class="uuid" v-if="isCheck"> ID: {{sUUID.replace(/^([^-]+).*?([^-]+)$/, '$1***$2')}} </div>-->
        <div class="uuid" v-if="isCheck"> ID: {{ fpId.slice(0, 8) + '****' + fpId.slice(-8)}} </div>
        <div class="info-box">
          <div class="tips_mini hidden" id="id_tips_mini">
            <div class="tips-mini_main">
              <img v-for="item in varGestures" :key="item" :src="item" alt="">
            </div>
          </div>
          <p v-for="(item,index) in varPrompts" :key="item">{{ index+1 }}、{{ $t(item) }}</p>
        </div>
        <div class="button-main hidden" v-if="isShowBtn">
          <div class="look-my-sx" @click="fnOpenVideo" :class="{disable:isDisableClick}">{{!isVideoRun?$t(sStartButtonLabel):$t('cancel')}}</div>
          <div class="look-my-sx ml-2" v-if="isCheck" @click="fnRefresh">{{$t('start_refresh')}}</div>
        </div>
      </div>
    </section>
  </main>
</template>

<script setup>
import {onMounted, reactive, ref, defineEmits, defineExpose, watch, unref} from "vue";
import {varGestures,varPrompts} from "./compone.data";

import RecordPalm from "@/components/RecordPalm.vue";
import {getUrlParam} from "@/config/utils";
const sTipsMsg = ref('start_info')
const sTipsMsgEnd = ref('start_info')
const nResultCode = ref(-1)
const sStartButtonLabel = ref('start_write')
const sUUID = ref('')
const refCenterDotEle = ref('')
const isCheck = ref(false)
const isChromeSp = ref(false)
const isChromeOpen = ref(false)
const sCode = ref('')
const sGestureClass = ref('')
const refMainContent = ref(null)
const isOpen = ref(false)
const isStartLoading =ref(false)
const isVideoRun = ref(false)
const isShowBtn = ref(false)
const isDisableClick = ref(false)
const sEspecialMsg = ref('')
const oCenterPositionStyle = reactive({
  left:'50%',
  top:'50%',
  transform:'translate(-50%,-50%) scale(1)'
})

const fpId = ref(localStorage.getItem('fpId'))
const isParams = ref(false)
const oBoxWh = reactive({
  width:220,
  height:220
})

const emit = defineEmits(['cbShowCode'])
let timeoutId = null
watch(
    () => unref(sTipsMsg),
    (v) => {
      if (isVideoRun.value) {
       if(timeoutId !== null){
         clearTimeout(timeoutId)
       }
       timeoutId = setTimeout(()=>{
         sTipsMsgEnd.value  = v
       },200)
      }else{
        sTipsMsgEnd.value  = sTipsMsg.value
      }
    },
    {
      immediate: false,
    },
);

onMounted(()=>{
  sUUID.value = generateUUID()
  fpId.value = localStorage.getItem('fpId')
  if(isCheck.value){
    sTipsMsg.value = 'start_info2'
    sStartButtonLabel.value = 'start_check'
  }
  const searchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(searchParams.entries());
  if (['1', '2'].includes(params.palm)) {
    if(['2'].includes(params.palm)){
      sTipsMsg.value = 'start_info2'
    }
    isParams.value = true
    // fnOpenVideo()
  }else{
    isShowBtn.value = true
  }
  const resizeObserver = new ResizeObserver(entries => {
    for (let entry of entries) {
      const { width, height } = entry.contentRect;
      oBoxWh.width = width
      oBoxWh.height = height
      // 在这里处理宽高变化的逻辑
    }
  });
  resizeObserver.observe(refMainContent.value);
  if( isChrome() && isMobile()){
    isChromeSp.value = true
  }
})

function isChrome() {
  // 使用 userAgent 属性来检测浏览器
  return /CriOS/.test(navigator.userAgent);
}

function isMobile() {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
}
// eslint-disable-next-line no-unused-vars
const oVideoRunOption = {
  isRun: function(status) {
    sGestureClass.value = status?'scan_start':'';
    isOpen.value = status
    isVideoRun.value = status
    isDisableClick.value = false
    if(status){
      if(isCheck.value){
        sTipsMsg.value = 'start_check'
      }else{
        sTipsMsg.value = 'start_write'
      }
      if(navigator.userAgent.indexOf("Chrome")){
        isChromeOpen.value = true
      }else{
        isChromeOpen.value = false
      }
      setTimeout(()=>{
        window.scrollTo({
          top: 0,
          behavior: 'smooth', // 平滑滚动
        });
      },500)
    }else{
      isChromeOpen.value = false
    }
  },
  basicTips: async function (value) {
    sTipsMsg.value = value;
  },
  especial: async function (value) {
    sEspecialMsg.value = value;
  },
  isStartLoading: async function (value) {
    isStartLoading.value = value;
  },
  ajaxData: async function (res) {
    sCode.value = res.score;
    sTipsMsg.value = res.msg
    if(res?.succ){
      fpId.value = localStorage.getItem('fpId')
      nResultCode.value = 0
    }else{
      nResultCode.value = 1
    }
    switch (res.code) {
      case 401:
        setTimeout(()=>{
          emit('cbShowCode',{isShowVerify:false,nPageType:1})
        },3000)
        break;
      case 100049:
        setTimeout(()=>{
          sessionStorage.removeItem('specialUrl')
          emit('cbShowCode',{ isShowVerify:true })
        },3000)
            break
      case 404:
        setTimeout(fnRefresh,3000)
        break;
      default:
        break;
    }

    if (isParams.value) {
      emit('cbShowCode',{ isPalmJump:true, result:res })
    }
  },
  isCheck: async function (value) {
    isCheck.value = value;
    isOpen.value = false
    sStartButtonLabel.value = 'start_check'
  }
};
//  根据不同的值做出不同的操作
function fnCallData(data){
  Object.keys(data).forEach(function(key) {
    oVideoRunOption[key](data[key])
  });
}
//  根据不同的值做出不同的操作
function fnCenterXy(data){
  let nLeft = (50+(data.nRemoveX||0))
  let nTop = (50+(data.nRemoveY||0))
  nLeft = nLeft>95?95:nLeft<5?5:nLeft
  nTop = nTop>95?95:nTop<5?5:nTop
  oCenterPositionStyle.left = Math.abs(oCenterPositionStyle.left-nLeft)<5?oCenterPositionStyle.left:nLeft+'%'
  oCenterPositionStyle.top = Math.abs(oCenterPositionStyle.top-nTop)<5?oCenterPositionStyle.top:nTop+'%'
}//  根据不同的值做出不同的操作
function fnCentreZ(data){
  oCenterPositionStyle.transform = `translate(-50%,-50%) scale(${data.nZoom||1})`
}

function fnChangeBasicMsg(value){
  sTipsMsg.value = value;
}
function fnChangeEspecialMsg(value){
  sEspecialMsg.value = value;
}

function fnOpenVideo(){
  if(!fnQualify() || isDisableClick.value) {
    return
  }
  if(isVideoRun.value){
    isOpen.value = false
    return
  }
  if(isCheck.value){
    sTipsMsg.value = 'start_check'
  }
  isStartLoading.value = false
  nResultCode.value = -1
  isOpen.value = true
  isDisableClick.value=true
}
function generateUUID(isImportant) {
  const searchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(searchParams.entries());
  isCheck.value = false
  sTipsMsg.value = 'start_info'
  sStartButtonLabel.value = 'start_write'
  if(!isImportant){
    if(Number(params?.palm) === 2 &&  params?.uid){
      isCheck.value = true
      return params.uid
    }
    if(!(['1', '2'].includes(params.palm)) && localStorage.getItem('uuid')){
      isCheck.value = true
      return localStorage.getItem('uuid')
    }
  }

  let d = new Date().getTime();
  if (typeof performance !== 'undefined' && typeof performance.now === 'function') {
    d += performance.now(); // 使用 performance.now() 获取更高分辨率的时间戳
  }

  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    const r = (d + Math.random() * 16) % 16 | 0;
    d = Math.floor(d / 16);
    return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
  });
}

function fnRefresh(){
  localStorage.removeItem('uuid')
  sessionStorage.removeItem('specialUrl')
  sUUID.value = generateUUID(true)
  isOpen.value = false
  fnQualify()
  // fnOpenVideo()
}

function fnQualify(){
  if(['1', '2'].includes(getUrlParam('palm'))){
   return true
  }
  if(sessionStorage.getItem('specialUrl')){
    return true
  }else{
    nResultCode.value = -1
    emit('cbShowCode',{isShowVerify:true})
    return false
  }
}

defineExpose({
  fnOpenVideo: fnOpenVideo
});
</script>
<style>
:root{
  --custom-bead-border: 2px dashed #1b84fe;
}
</style>

<style scoped>

.palm_body{
  display: flex;
  flex-direction: column;
}
.content_body{
  overflow-x: hidden;
}
.palm_head{
  width: 100%;
  height: 160px;
  margin-bottom: -60px;
  display: flex;
  text-align: center;
  flex-direction: column;
  background-size: cover;
  justify-content: center;
  position: relative;
  z-index: 99999;
}
.palm_head.tips_suc {
  color: #6fc140;
}

.palm_head.tips_err {
  color: #f56c6c;
}
.palm_main{
  width: 100%;
  height: 600px;
  position: relative;
  background: url("../assets/image/bg_img.png") no-repeat;
  background-size: contain;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.5s ease-in-out;
}
.palm-main_content {
  width: 400px;
  height: 400px;
  margin-top: 20px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  position: relative;
  background: url("../assets/image/hand_bg.png") no-repeat;
  background-size: contain;
}
.palm_footer{
  width: 8rem;
  margin: 0  auto;
  flex: 1;
  min-height: 0;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  font-size: 28px;
}
.palm_footer .uuid{
  text-align: center;
  margin-bottom: 10px;
}
.palm_footer p {
  color: #979292;
  line-height: 45px;
}
.tips_mini {
  text-align: center;
  margin-bottom: 30px;
}
.tips_mini .tips-mini_main {
  display: flex;
  align-items: flex-end;
  justify-content: space-around;
  height: 120px;
}

.tips_mini img {
  max-width: 100px;
  transition: all 0.3s ease-in-out;
  filter: hue-rotate(345deg);
  opacity: 0.7;
}

.scan_start .palm_main{
  transform: scale(1.25);
  margin-bottom: 40px;
}
.scan_start .chrome_sp{
  margin-top:40px;
}
.look-my-sx {
  flex: 1;
  line-height: 1.25rem;
  text-align: center;
  color: #fff;
  font-size: 0.35rem;
  font-weight: normal;
  cursor: pointer;
  border-radius: 8px;
  background: #1b84fe;
}
.button-main {
  display: flex;
  margin: 0.3rem 0;
  justify-content: space-evenly;
}
.spinnerFourBox{
  width: 100%;
  height: 100%;
  background: #0000005c url('../assets/image/loading.gif') no-repeat center center;
  background-size: 100%;
}
.rotate_img{
  position: absolute;
  width: 100%;
  height: 400px;
  animation-name: slideDown-fa2b16b6;
  animation-duration: 4s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  background: url('../assets/image/mask_line.png') no-repeat;
  background-size: contain;
  z-index: 99;
}
.look-my-sx.disable {
  background: #c1c1c1;
  color: #818181;
}
@keyframes slideDown-fa2b16b6 {
  0% {
    transform: translateY(-30vh)
  }

  to {
    transform: translateY(40vh)
  }
}
.guide_body{
  width: 250px;
  height: 250px;
  position: absolute;
  z-index: 9;
  top: 185px;
}
.guide-main_box {
  width: 100%;
  height: 100%;
//border: 1px solid #1c84fd;
  position: relative;
  border-radius: 50%;
//margin: 50px auto 15px;
}
.guide-main_fix {
  width: 100%;
  height: 100%;
  //background: red;
  //border: 2px dashed red;
  border-radius: 50%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
}
.guide-main_fixC{
  content: ' ';
  width: 50px;
  height: 50px;
  border: var(--custom-bead-border);
  border-radius: 50%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
}
.guide-main_fixX{
  width: 100%;
  height: 1px;
  border-top: var(--custom-bead-border);
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
  //display: none;
}
.guide-main-fixY{
  height: 100%;
  width: 1px;
  border-left: var(--custom-bead-border);
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
  //display: none;
}

.guide-main_dot {
  width: 40px;
  height: 40px;
  background: #1c84fd;
  background: url('../assets/image/icon_bead.png') no-repeat;
  background-size: contain;
  //border:2px dashed #1c84fd;
  border-radius: 50%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
}
.guide-main_dot:before{
  display: none;
  content: ' ';
  width: 20px;
  height: 20px;
  background: #1c84fd;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
  border-radius: 50%;
}

</style>
