<template>
  <section class="video_main">
    <div class="video_body">
      <video ref="videoRef" class="canvas_main" playsinline autoplay></video>
      <canvas ref="canvasRef" class="canvas_main"></canvas>
    </div>
  </section>
</template>
<script setup>
import {defineEmits, defineProps, onMounted, ref, unref, watch} from 'vue';
import {FilesetResolver, HandLandmarker} from "@mediapipe/tasks-vision";
import {drawConnectors, drawLandmarks} from "@mediapipe/drawing_utils";
import {HAND_CONNECTIONS} from "@mediapipe/hands";
import {cloneDeep, size} from "lodash-es";
import {dialogShow, getUrlParam} from "@/config/utils";
import i18n from "@/language";
import {apiCheck, apiSave} from "@/config/api";

const lang = i18n.global.t
const videoRef = ref(null)
const canvasRef = ref(null)
const sBasicTips = ref('')
const sEspecial = ref('')
const nVideoW = ref(0)
const nVideoH = ref(0)
const lastVideoTime = ref(-1);
const isRequestApi = ref(true)
const isPlaying = ref(false)
const isDrawing = ref(false)
const isRunPlay = ref(true)
const isAnimation = ref(false)
const oCheckResult = ref({})
const nChangeColorIdx = ref(0) // 定时器索引值
const nChangeColorTime = ref(0)  // 次数
let handLandMarker = undefined;
// let ve;
let u = []
let d = []
let ae = 0
let be = 0
let ue = 0
let he = 0
const props = defineProps({
  oBoxWh: {
    type: Object,
    default: () => {
      return {
        width: 0,
        height: 0
      }
    }
  },
  isOpen: {
    type: Boolean,
    default: false
  },
  isCheck: {
    type: Boolean,
    default: false
  },
  sUid: {
    type: String,
    default: ''
  },
  refCenterDot: {
    type: Object,
    default: ()=>{
      return {}
    }
  }
})

const emits = defineEmits(['cbOperateData', 'cbChangeBasicMsg', 'cbChangeEspecialMsg','cbCentreXy','cbCentreZ'])

watch(
    () => unref(sBasicTips),
    (v) => {
      if (props.isOpen) {
        emits('cbChangeBasicMsg', v)
        emits('cbOperateData', {basicTips: v})
      }
    },
    {
      immediate: false,
    },
);
watch(
    () => unref(sEspecial),
    (v) => {
      if (props.isOpen) {
        emits('cbChangeEspecialMsg', v)
        emits('cbOperateData', {especial: v})
      }
    }
);
watch(
    () => props.isOpen,
    () => {
      fnPlayVideo()
    },
);

watch(
    () => props.oBoxWh,
    () => {
      if (isPlaying.value) {
        fnSetVideoWh(videoRef.value.videoWidth || 480, videoRef.value.videoHeight || 640)
      }
    },
    {
      immediate: true,
    },
);

onMounted(async () => {
  await fnInitHandLand()
})

// eslint-disable-next-line no-unused-vars
function getCheckCanvas() {
  if (nChangeColorIdx.value) {
    clearInterval(nChangeColorIdx.value)
  }

  let canvas = document.getElementById('myCanvas');


  if (!canvas) {
    canvas = document.createElement('canvas');
    canvas.id = 'myCanvas';
    canvas.width = window.innerWidth;
    canvas.height = window.innerHeight;
    canvas.style.position = 'fixed';
    canvas.style.top = '0';
    canvas.style.left = '0';
    canvas.style.zIndex = '9999';
    document.body.appendChild(canvas);
  }
  canvas.style.display = 'block'
  const ctx = canvas.getContext('2d');
  const oFixedCenterXy = props.refCenterDot?.getBoundingClientRect()
  const centerX = oFixedCenterXy.left?oFixedCenterXy.left+23:canvas.width / 2;
  const centerY = oFixedCenterXy.top?oFixedCenterXy.top+23:canvas.height / 3 + 35;

  // 定义改变背景颜色的函数
  function changeBackgroundColor() {
    nChangeColorTime.value += 1
    if (nChangeColorTime.value > 6) {
      nChangeColorTime.value = 0

      if (size(oCheckResult.value)) {
        if (canvas) {
          canvas.style.display = 'none'
        }
        emits("cbOperateData", {isRun: false, basicTips: oCheckResult.value.msg, ajaxData: oCheckResult.value})
        dialogShow(oCheckResult.value.succ, lang(oCheckResult.value.msg))
        fnCloseVideo()
        clearInterval(nChangeColorIdx.value)
      }
      return
    }
    const colors = ['#00FFFF', '#FF00FF', '#0000FF', '#00FF00', '#FF0000', '#FFF200']; // 可选的颜色列表
    const randomIndex = Math.floor(Math.random() * colors.length); // 随机选择一个颜色
    const color = colors[randomIndex];
    ctx.clearRect(0, 0, canvas.width, canvas.height); // 清除画布
    ctx.fillStyle = color; // 设置背景颜色
    ctx.fillRect(0, 0, canvas.width, canvas.height); // 填充整个画布
    drawTransparentCircle(); // 重新绘制透明圆圈
  }

  // 绘制透明圆圈
  function drawTransparentCircle() {
    ctx.save(); // 保存当前绘图状态
    ctx.beginPath();
    ctx.arc(centerX, centerY, 90, 0, 2 * Math.PI);
    ctx.closePath();
    ctx.clip();
    ctx.clearRect(0, 0, canvas.width, canvas.height); // 清除中间圆形区域
    ctx.restore(); // 恢复之前的绘图状态
    // ctx.strokeStyle = 'white'; // 设置边框颜色
    ctx.stroke(); // 绘制边框
  }

  // 每 300 毫秒调用一次函数以改变背景颜色
  nChangeColorIdx.value = setInterval(changeBackgroundColor, 200)
  return canvas;
}

async function fnInitHandLand() {
  const vision = await FilesetResolver.forVisionTasks("./vision");
  handLandMarker = await HandLandmarker.createFromOptions(
      vision,
      {
        baseOptions: {
          modelAssetPath: "./vision/hand_landmarker.task",
          delegate: "GPU"
        },
        runningMode: "VIDEO",
        numHands: 1
      });
}

function fnCheckInitData() {
  return new Promise((resolve) => {
    const interval = setInterval(() => {
      if (handLandMarker) {
        clearInterval(interval);
        resolve(true);
      }
    }, 100);
  });
}

async function fnPlayVideo() {
  if (props.isOpen) {

    getUserMedia({video: true, audio: false}, async (stream) => {
      emits('cbOperateData', {isStartLoading: true})
      await fnCheckInitData()
      videoRef.value.srcObject = stream;
      nChangeColorTime.value = 0
      videoRef.value.play();
      isRunPlay.value = true
      isRequestApi.value = true
      oCheckResult.value = {}
      // 获取视频轨道的设置信息
      const settings = stream.getVideoTracks()[0].getSettings();
      // 获取分辨率
      videoRef.value.resolution = {width: settings.width, height: settings.height}
      // 获取视频轨道的设置信息
      await waitForCondition()
      lastVideoTime.value = -1
      isAnimation.value = true
      fnPredictWebcam()
      emits('cbOperateData', {isRun: true})
    }, () => {
      dialogShow(false, lang('video_open_err'))
      fnCloseVideo()
    })
  } else {
    fnCloseVideo()
  }
}

function fnCloseVideo() {
  isAnimation.value = false
  oCheckResult.value = {}
  emits('cbOperateData', {isRun: false})
  if (videoRef.value && videoRef.value.srcObject && videoRef.value.srcObject.getTracks()) {
    videoRef.value.srcObject.getTracks()[0].stop()
  }
}

let results = undefined;
function fnPredictWebcam() {
  if (!isAnimation.value) {
    return
  }
  if (isRunPlay.value) {
    const video = videoRef.value

    let startTimeMs = performance.now();

    if (lastVideoTime.value !== video?.currentTime) {
      lastVideoTime.value = video?.currentTime;
      try {
        results = handLandMarker.detectForVideo(video, startTimeMs);
      } catch (e) {
        dialogShow(false, e)
      }
    }

    if (results && fnVerifyLeft(results)) {
      fnDrawSkeleton(results)

    }
  }
  window.requestAnimationFrame(fnPredictWebcam);
}

/**
 * 判断左右手
 * @param results
 */
function fnVerifyLeft(results) {
  let isRight = true;
  if (results.handedness.length > 0) {
    const ht = results.handedness[0];
    let xt = ht[0]
        , Kt = xt.score
        , Cn = xt.categoryName;
    isRight = Kt > .5 && Cn === "Right"
    sBasicTips.value = isRight ? '' : 'palm_text'
    if (!isRight && isDrawing.value) {
      fnClearCanvas()
    }
  }
  return isRight
}

function fnDrawSkeleton(results) {
  const canvasElement = canvasRef.value;
  const canvasCtx = canvasElement.getContext("2d");

  if (isDrawing.value) {
    canvasCtx.save();
    canvasCtx.clearRect(0, 0, canvasElement.width, canvasElement.height);
  }
  if (results.landmarks && results.landmarks.length) {
    isRunPlay.value = false
    for (const landmarks of results.landmarks) {
      // 判断距离
      fnVerifyPalm(landmarks)

      if (isDrawing.value) {
        //去掉默认的隐藏
        landmarks.forEach((v) => {
          delete v.visibility
        })
        // 绘制骨骼
        drawConnectors(canvasCtx, landmarks, HAND_CONNECTIONS, {
          color: "#00FF00",
          lineWidth: 5
        });
        drawLandmarks(canvasCtx, landmarks, {color: "#FF0000", lineWidth: 2});
      }
    }
  }else{
    emits('cbCentreXy',{nRemoveX:0,nRemoveY:0})
    emits('cbCentreZ',{nZoom:1})
  }
  if (isDrawing.value) {
    canvasCtx.restore();
  }
}

function fnVerifyPalm(ft) {
  let l = []
  let c = []
  for (let xt = 0; xt < ft.length; xt++) {
    let Kt = ft[xt];
    const Cn = Math.trunc(Kt.x * videoRef.value.videoWidth)
        , Gr = Math.trunc(Kt.y * videoRef.value.videoHeight);
    l[xt] = [Cn, Gr]
  }
  for (let xt = 0; xt < 5; xt++) {
    let Kt, Cn, Gr;
    xt === 0 ? (Kt = l[17],
        Cn = l[2],
        Gr = l[4]) : (Kt = l[0],
        Cn = l[4 * xt + 2],
        Gr = l[4 * xt + 4]),
        c[xt] = fingerUp(Kt, Cn, Gr)
  }

  const sStatus = Kc(c)
  if (sStatus === '5') {
    // 判断是否居中
    if (!checkPositionAlignment(l)) {
      fnVideoPlayAgain()
      return
    }
    // 对角线不对
    if (!Dt(l)) {
      fnVideoPlayAgain()
      return
    }
    if (props.isCheck && !Wt(l)) {
      fnVideoPlayAgain()
      // 角度不对
      return
    }

    if (fnOn(l) && isRequestApi.value) {
      isRequestApi.value = false
      isRunPlay.value = false
      I0()
      return
    }
  }else{
    sBasicTips.value = 'palm_guide_tip5'
    emits('cbCentreXy',{nRemoveX:0,nRemoveY:0})
    emits('cbCentreZ',{nZoom:1})
  }
  fnVideoPlayAgain()
  // checkPositionAlignment(landmarks)
}

// 手指是否伸直
function fingerUp(a, b, c) {
  return Number(distance(a, c) > distance(a, b))
}


// 两点之前的坐标距离
function distance(a, b) {
  return Math.sqrt(b.map((v, i) => (v - a[i]) ** 2).reduce((sum, v) => sum + v, 0))
}

function Dt(l) {
  ue = _s(l[1][0], l[1][1], l[17][0], l[17][1]) / videoRef.value.videoWidth;
  let nZoom = 0
  if (0.42 < ue && 0.8 > ue) {
    emits('cbCentreZ',{nZoom})
    return true
  } else {
    if (0.42 > ue) {
      nZoom = 1+ (0.42-ue)/0.42
      sBasicTips.value = 'palm_tip_closer';
    } else if (0.8 < ue) {
      nZoom = 1+(ue-0.8)/0.8
      sBasicTips.value = 'palm_tip_farther';
    }
    emits('cbCentreZ',{nZoom})
    sEspecial.value = "对角线不对" + ue.toFixed(2);
    return false;
  }
}


function _s(We, ft, ht, xt) {
  const Kt = We - ht
      , Cn = ft - xt;
  return Math.sqrt(Kt * Kt + Cn * Cn)
}

function Wt(d) {
  let l = cloneDeep(d)
  const ft = l[9][0] - l[0][0];
  const ht = l[9][1] - l[0][1];
  const Kt = Math.atan2(ht, ft) * (180 / Math.PI);
  be = Kt < 0 ? Kt + 360 : Kt;
  // && l[2] > l[17]
  if (180 < be && 360 > be) {
    return true
  } else {
    sEspecial.value = "角度不对" + be.toFixed(2);
    return false
  }
}

function fnOn(l) {
  let We = false;
  if (sBasicTips.value = 'palm_tip_keep', u.length === 0) {
    u.push({
      centerX: ae,
      centerY: he,
      length: ue,
      angle: be
    });
    We = false;
  } else if (u.length === 1) {
    let ft = u[0];
    let ht = 0.05;
    let xt = gr(ft.centerX, ae, ht);
    let Kt = gr(ft.centerY, he, ht);
    let Cn = gr(ft.length, ue, ht);
    let Gr = gr(ft.angle, be, ht);
    if (xt && Kt && Cn && Gr) {
      u.push({
        centerX: ae,
        centerY: he,
        length: ue,
        angle: be
      });
    } else {
      sEspecial.value = "来回移动";
      u = [];
      u.push({
        centerX: ae,
        centerY: he,
        length: ue,
        angle: be
      });
      We = false;
    }
  } else if (u.length === 2) {
    let ft = u[0];
    let ht = 0.05;
    let xt = gr(ft.centerX, ae, ht);
    let Kt = gr(ft.centerY, he, ht);
    let Cn = gr(ft.length, ue, ht);
    let Gr = gr(ft.angle, be, ht);
    if (xt && Kt && Cn && Gr) {
      d = [];
      for (let Nr = 0; Nr < l.length; Nr++) {
        d.push(l[Nr][0]);
        d.push(l[Nr][1]);
      }
      sBasicTips.value = 'palm_tip_keep';
      emits('cbCentreXy',{nRemoveX:0,nRemoveY:0})
      emits('cbCentreZ',{nZoom:1})
      We = true;
    } else {
      sEspecial.value = "来回移动";
      u = [];
      u.push({
        centerX: ae,
        centerY: he,
        length: ue,
        angle: be
      });
      We = false;
    }
  }
  return We;
}

function gr(We, ft, ht) {
  return Math.abs(We - ft) / (ft || 1) < ht;
}

function I0() {
  if (props.isCheck && nChangeColorTime.value !== 0) {
    isRunPlay.value = true
    return
  }
  // 获取视频的原始宽高
  const videoWidth = videoRef.value.videoWidth;
  const videoHeight = videoRef.value.videoHeight;

  const We = document.createElement("canvas");
  const ft = We.getContext("2d");
  We.width = videoWidth;
  We.height = videoHeight;

  if (ft) {
    // ft.drawImage(videoRef.value, offsetX, offsetY, size ,size ,0, 0, size ,size );
    ft.drawImage(videoRef.value, 0, 0, videoWidth, videoHeight);
    // ve = ft.getImageData(0, 0, We.width, We.height).data;

    // We.toBlob(function (blob) {
    //   const screenshotImage = new Image();
    //   screenshotImage.src = URL.createObjectURL(blob);
    //   screenshotImage.style.display = 'none';
    //   screenshotImage.alt = offsetY
    //   document.body.appendChild(screenshotImage);
    //
    // }, 'image/png', 1.0); // 1.0 is the highest quality
  }
  postData(We.toDataURL('image/jpeg', 1))
  if (props.isCheck && nChangeColorTime.value === 0) {
    getCheckCanvas()
  }
}

async function postData(we) {
  try {
    let tempApi = props.isCheck?apiCheck: apiSave
    let query = (getUrlParam('palm') == 1 || getUrlParam('palm') == 2)?location.search.slice(1):sessionStorage.getItem('specialUrl')
    if(!query){
      emits("cbOperateData", {basicTips: lang('palm_timeout_tip1'), isRun: false,ajaxData: {}})
      fnCloseVideo()
      return
    }
    const data = await tempApi({
      uuid: props.sUid,
      img: we,
      results,
      query
    })
    isRequestApi.value = true

    if (data.code === 0) {
      // 验证
      if (props.isCheck) {
        data.msg = data?.succ ? 'code_check_succ' : 'code_check_error'
        oCheckResult.value = data
        isRequestApi.value = false
      } else if (data.succ && !props.isCheck) {
        // 录入成功
        localStorage.setItem('uuid', props.sUid)
        data.msg = 'code_save_succ'
        emits("cbOperateData", {isCheck: true, isRun: false, ajaxData: data})
        fnCloseVideo()
        dialogShow(true, lang(data.msg))
        return
      }
    }else{
      switch (data.code){
        case  404:
          break
        case  100085:
          data.msg = 'code_check_error_100085'
          break
        case  100049:
          data.msg = 'code_check_error_100049'
          break
        default:
          data.msg =  !props.isCheck?'code_save_error' : 'code_check_error'
          break
      }
      // 失败
      if (!props.isCheck) {
        emits("cbOperateData", {isRun: false, basicTips: data.msg || '', ajaxData: data})
        isRunPlay.value = false
        fnCloseVideo()
        dialogShow(false, lang(data.msg))
        return
      } else {

        oCheckResult.value = data
        isRequestApi.value = false
      }
    }
    fnVideoPlayAgain()
  } catch (e) {
    fnVideoPlayAgain()
  }
}

function fnVideoPlayAgain() {
  isRunPlay.value = true
  // predictWebcam()
}

function Kc(We) {
  switch (!0) {
    case (We[0] === 1 && We[1] === 0 && We[2] === 0 && We[3] === 0 && We[4] === 0):
      // sBasicTips.value = 'palm_guide_tip1'
      return "good";
    case (We[0] === 0 && We[1] === 1 && We[2] === 0 && We[3] === 0 && We[4] === 0):
      // sBasicTips.value = 'palm_guide_tip1'
      return "1";
    case (We[0] === 0 && We[1] === 0 && We[2] === 1 && We[3] === 0 && We[4] === 0):
      // sBasicTips.value = 'palm_guide_tip1'
      return "what?";
    case (We[0] === 0 && We[1] === 1 && We[2] === 1 && We[3] === 0 && We[4] === 0):
      // sBasicTips.value = 'palm_guide_tip1'
      return "2";
    case (We[0] === 0 && We[1] === 1 && We[2] === 1 && We[3] === 1 && We[4] === 0):
      // sBasicTips.value = 'palm_guide_tip1'
      return "3";
    case (We[0] === 0 && We[1] === 1 && We[2] === 1 && We[3] === 1 && We[4] === 1):
      // sBasicTips.value = 'palm_guide_tip1'
      return "4";
    case (We[0] === 1 && We[1] === 1 && We[2] === 1 && We[3] === 1 && We[4] === 1):
      return "5";
    case (We[0] === 1 && We[1] === 0 && We[2] === 0 && We[3] === 0 && We[4] === 1):
      return "six";
    case (We[0] === 0 && We[1] === 0 && We[2] === 1 && We[3] === 1 && We[4] === 1):
      // sBasicTips.value = 'palm_guide_tip1'
      return "OK";
    case We.every(ft => ft === 0):
      // sBasicTips.value = 'palm_guide_tip1'
      return "stone";
    default:
      return "not in detect range..."
  }
}

function checkPositionAlignment(l) {
  let isAligned = false; // 初始设置未对齐标志为假

  const kn = window.innerHeight
  // 计算横向和纵向中心点
  let horizontalCenter = (l[0][0] + l[9][0]) / 2;
  let verticalCenter = (l[0][1] + l[9][1]) / 2;

  // 计算相对于视频宽度和高度的位置
  let relativeHorizontalPos = horizontalCenter / videoRef.value.videoWidth;
  let relativeVerticalPos = ((kn - 50 - nVideoH.value) * 0.5 + verticalCenter) / (kn - 50);
  ae = relativeHorizontalPos
  he = relativeVerticalPos

  // 检查中心点是否在指定范围内
  let nRemoveX,nRemoveY = 0
  if (relativeHorizontalPos > 0.4 && relativeHorizontalPos < 0.6 && relativeVerticalPos > 0.3 && relativeVerticalPos < 0.7) {
    isAligned = true;
  } else {
    isAligned = false;
    // 根据相对位置设置不同的提示
    if (relativeHorizontalPos < 0.4) {
      nRemoveX = Math.max(0, (0.4 - relativeHorizontalPos))* 100 / 0.4;
      nRemoveX=nRemoveX>50?50:nRemoveX
      sBasicTips.value = 'palm_tip_left';
    } else if (relativeHorizontalPos >= 0.6) {
      nRemoveX = Math.max(0, (relativeHorizontalPos - 0.6) ) * 100 / 0.6 * -1;
      nRemoveX=nRemoveX<-50?-50:nRemoveX
      sBasicTips.value = 'palm_tip_right';
    } else if (relativeVerticalPos < 0.3) {
      nRemoveY = Math.max(0, (0.3 - relativeVerticalPos) * 100 / 0.3)* -1;
      sBasicTips.value = 'palm_tip_down';
    } else if (relativeVerticalPos >= 0.7) {
      nRemoveY = Math.max(0, (relativeVerticalPos - 0.7) * 100 / 0.7);
      sBasicTips.value = 'palm_tip_up';
    }
    emits('cbCentreXy',{nRemoveY,nRemoveX})
    sEspecial.value = "中心点不对" + relativeHorizontalPos.toFixed(2) + "||" + relativeVerticalPos.toFixed(2);
  }

  return isAligned;
}

// 清除画布
function fnClearCanvas() {
  const canvasElement = canvasRef.value;
  const canvasCtx = canvasElement.getContext("2d");
  canvasCtx.save();
  canvasCtx.clearRect(0, 0, canvasElement.width, canvasElement.height);
  canvasCtx.restore();
}

// 修改视频大小
function waitForCondition(interval = 20, maxAttempts = 250) {
  return new Promise((resolve) => {
    let attempts = 0;

    function check() {
      attempts++;
      const vw = videoRef.value.videoWidth;
      const vh = videoRef.value.videoHeight;
      if (!!vw && !!vh) {
        fnSetVideoWh(vw, vh)
        resolve(true);
      } else if (attempts < maxAttempts) {
        setTimeout(check, interval);
      } else {

        fnSetVideoWh(480, 640)
        resolve(false);
      }
    }

    // 开始检测
    check();
  });
}

function fnSetVideoWh(videoWidth, videoHeight) {
  const {width: divWidth, height: divHeight} = props.oBoxWh;

  // 计算视频应该缩放的比例，确保宽和高都不得小于260
  const scaleFactor = Math.max(divWidth / videoWidth, divHeight / videoHeight);

  // 计算缩放后的宽高
  const scaledVideoWidth = Math.max(divWidth, videoWidth * scaleFactor);
  const scaledVideoHeight = Math.max(divHeight, videoHeight * scaleFactor);
  const ratio = detectOS(3) ? 1.35 : 1
  // 设置视频和画布的宽高
  // nVideoW.value = videoRef.value.width = canvasRef.value.width = scaledVideoWidth*ratio;
  // nVideoH.value = videoRef.value.height = canvasRef.value.height = scaledVideoHeight*ratio;
  nVideoW.value = scaledVideoWidth*ratio;
  nVideoH.value = scaledVideoHeight*ratio;
  isPlaying.value = true
}

function detectOS(type) {
  const typeList = {
    1:'Other',
    2:'Android',
    3:'iOS'
  }
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;

  // 包含iOS关键词，则为iOS设备
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return "iOS" === typeList[type];
  }

  // 包含Android关键词，则为Android设备
  if (/android/i.test(userAgent)) {
    return "Android" === typeList[type];
  }
  // 其他情况
  return "Other" === typeList[type];
}
function getUserMedia(constrains, success, error) {
  if (navigator?.mediaDevices?.getUserMedia) {
    // 最新标准API
    navigator.mediaDevices
        .getUserMedia(constrains)
        .then(success)
        .catch(error);
  } else if (navigator.webkitGetUserMedia) {
    // webkit内核浏览器
    navigator
        .webkitGetUserMedia(constrains)
        .then(success)
        .catch(error);
  } else if (navigator.mozGetUserMedia) {
    // Firefox浏览器
    // eslint-disable-next-line no-undef
    navagator.mozGetUserMedia(constrains).then(success).catch(error);
  } else if (navigator.getUserMedia) {
    // 旧版API
    navigator.getUserMedia(constrains).then(success).catch(error);
  } else {
    console.log("你的浏览器不支持访问用户媒体设备")
  }
}
</script>

<style scoped>
.video_main {
  width: 100%;
  height: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 1;
  transform: scaleX(-1);
}

.video_body {
  position: relative;
  width: 100%;
  height: 100%;
  overflow:hidden;
}

.video_body:before {
  content: ' ';

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-46%, -50%);
  width: 90%;
  height: 90%;
  z-index: 9;
  background: url("../assets/image/hand_line_you.png") no-repeat;
  background-size: contain;
  display: none;
}

.canvas_main {
  position: absolute;
  top: 25%;
  left: 50%;
  transform: translate(-50%, -25%);
  clip-path: inset(0 20px);
}
</style>
