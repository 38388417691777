import { createI18n } from 'vue-i18n';
import {getUrlParam} from "@/config/utils";
// 导入当前目录及其子目录下所有以 .js 结尾的文件
const modules = require.context('./locales', true, /\.js$/);
function getLangAll() {
    let message = {};
    modules.keys().forEach(path => {
        const module = modules(path).default;
        if (module) {
            let pathName = path.split('/').pop().split('.')[0];
            message[pathName] = module;
        }
    });
    return message;
}
let language = getUrlParam('lang')  || navigator.language; //  获取本地存储 || 根据浏览器语言设置

// 注册 i18n 实例并引入语言文件
const i18n = createI18n({
    locale: language, // 设置默认语言
    fallbackLocale: 'zh-CN', // 默认回退语言
    messages: getLangAll()
});



export default i18n;
